<template>
	<viewer>
		<user-info :userInfo="getUserInfo"></user-info>
		<loan-detail :loanInfo.sync="emergencyFormInfo.loanInfo" :showEditBtn="false" :isEditable="false" />
		<a-descriptions v-if="emergencyFormInfo.logbook" bordered :title="$t('table.applyInfo.vehicleLabel.logFileInfo')" size="default">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')" :span="3">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.logbook.logbookFile" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
				{{ emergencyFormInfo.logbook.make }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
				{{ emergencyFormInfo.logbook.model }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
				{{ emergencyFormInfo.logbook.year }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
				{{ emergencyFormInfo.logbook.registrationNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
				{{ emergencyFormInfo.logbook.frameNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
				{{ emergencyFormInfo.logbook.engineNo }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')">
				<div class="box">
					<div class="list" v-for="(item, idx) in emergencyFormInfo.logbook.belongs" :key="idx">
						<div class="span_labe"> PIN:{{ emergencyFormInfo.logbook.belongs[idx].pin }} </div>
						<div class="span_labe"> Name:{{ emergencyFormInfo.logbook.belongs[idx].name }} </div>
					</div>
				</div>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
				{{ emergencyFormInfo.logbook.vehicleUse }}
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions
			v-if="emergencyFormInfo.loanInfo && emergencyFormInfo.loanInfo.loanType === 6"
			bordered
			:title="$t('table.applyInfo.vehicleLabel.estimateBookFileTxt')"
			size="default"
		>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimateBookFile')" :span="3">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.valuation.file" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
				{{ emergencyFormInfo.valuation.make }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
				{{ emergencyFormInfo.valuation.model }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
				{{ emergencyFormInfo.valuation.year }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
				{{ emergencyFormInfo.valuation.registrationNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
				{{ emergencyFormInfo.valuation.frameNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
				{{ emergencyFormInfo.valuation.engineNo }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimate')">
				{{ emergencyFormInfo.valuation.valuation }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.endValue')">
				{{ emergencyFormInfo.valuation.saleValuation }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.createTime')">
				{{ emergencyFormInfo.valuation.reportTime }}
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions :title="$t('table.applyInfo.vehicleLabel.insurance')" bordered size="default">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.allInsurance')">
				{{ emergencyFormInfo.isAllRisks == 1 ? $t('page.yes') : $t('page.no') }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.expTimeRe')" :span="2">
				{{ emergencyFormInfo.expireTime }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.stickersPic')" :span="3">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.insurancePic" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.financialLabel.flow')" size="default">
			<a-descriptions-item :span="3">
				<a-table
					style="font-size: 12px"
					:columns="columns"
					size="default"
					:rowKey="(record, index) => index"
					:dataSource="emergencyFormInfo.transactions"
					:pagination="false"
					bordered
				>
					<a slot="file" slot-scope="text, obj" @click="openUrl(obj)">{{ obj.file }}</a>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<a-button
					type="primary"
					@click="CheckLoanDetail"
					style="margin-left: 20px"
					:disabled="emergencyFormInfo.transactions && emergencyFormInfo.transactions.length == 0"
				>
					{{ $t('page.checkReview') }}
				</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered title="Loan Application" size="small">
			<a-descriptions-item label="Loan Application Form">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.applicationFormFile" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions v-if="emergencyFormInfo.loanOfferFile" bordered title="loan offer" size="small">
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.number')" :span="3">
				{{ emergencyFormInfo.loanOfferNo }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.file')" :span="3">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.loanOfferFile" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions v-if="emergencyFormInfo.expireDate" bordered title="Insurance Endorsement" size="small">
			<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.endorsementLetters" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item label="Expiry Date" :span="3">
				{{ emergencyFormInfo.expireDate }}
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions v-if="emergencyFormInfo.loanCalculator" bordered title="Loan Calculator" size="small">
			<a-descriptions-item label="Loan Calculator">
				<UploadPreviewFile :uploadFilePath="emergencyFormInfo.loanCalculator" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="default">
			<a-descriptions-item>
				<a-table
					style="font-size: 12px"
					:columns="supCloums"
					size="default"
					:rowKey="(record, index) => index"
					:dataSource="emergencyFormInfo.supplementFiles"
					:pagination="false"
					bordered
				>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
							!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
						}}</a>
					</span>
				</a-table>
			</a-descriptions-item>
		</a-descriptions>
	</viewer>
</template>
<script>
import userInfo from '@/views/businessComponents/UserInfo'
import loanDetail from '@/views/emergencyLoan/components/loanDetail.vue'
export default {
	props: ['userInfo', 'emergencyFormInfo'],
	components: { userInfo, loanDetail },
	data() {
		return {
			getUserInfo: {
				createSource: this.userInfo.createSource,
				createTime: this.emergencyFormInfo.createTime,
				creatorId: this.userInfo.creatorId,
				creatorName: this.emergencyFormInfo.creatorName,
				customerId: this.userInfo.customerId,
				statusCode: this.userInfo.statusCode,
				caseId: this.userInfo.caseId,
				casePrefix: this.userInfo.casePrefix
			}
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		}
	}
}
</script>
