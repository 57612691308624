<template>
	<div class="case-details">
		<slot name="pageBtn"></slot>
		<user-info v-if="userInfo && processStatus.statusCode !== 'disburse_success'" :userInfo="userInfo" :loanInfo="loanInfo">
			<template slot="titleBtn">
				<slot name="titleBtn" :loanInfo="loanInfo"></slot>
			</template>
		</user-info>
		<loan-info-form v-if="beingRevised" :beingRevised="beingRevised" :loanInfo="loanInfo" :loanCarInfos="loanCarInfos" @setDataChange="setDataChange" />
		<loan-info-detail
			v-else
			:loanInfo="loanInfo"
			:loanCarInfos="loanCarInfos"
			:detailData="detailData"
			:showEditBtn="showEditBtn"
			:isEditable="editable"
			:parentNode="parentNode"
			@setDataChange="setDataChange"
		/>
		<insur-info
			v-if="dataComplete"
			:detailData="detailData"
			:insuranceData="insuranceData"
			:isEditable="editable"
			:showEditUpfront="showEditUpfront"
			:beingRevised="beingRevised" 
			@setInsuranceCompleted="setInsuranceCompleted"
			:statusCode="this.processStatus.processNode.id"
		></insur-info>
		<create-info-detail ref="createInfo" :createInfo="createInfo" />
		<a-tabs v-if="dataComplete">
			<a-tab-pane key="1" :tab="$t('table.applyInfo.tabsMenu.personal')">
				<person-info-detail :personalInfo="personalInfo"></person-info-detail>
			</a-tab-pane>
			<a-tab-pane key="2" :tab="$t('table.applyInfo.tabsMenu.vehicle')">
				<vehicle-info :loanCarInfos="loanCarInfos" :parentNode="parentNode" :loanType="this.loanInfo.loanType"></vehicle-info>
			</a-tab-pane>
			<a-tab-pane key="3" :tab="$t('table.applyInfo.tabsMenu.financial')">
				<financial-info :financeInfo="financeInfo"></financial-info>
			</a-tab-pane>
			<a-tab-pane key="4" :tab="$t('table.applyInfo.tabsMenu.application')">
				<application-info :loanApplyInfo="loanApplyInfo"></application-info>
			</a-tab-pane>
			<a-tab-pane key="5" :tab="$t('table.applyInfo.applicationLabel.creditFile')">
				<credit-file-info :creditReport="creditReport" :personalInfo="personalInfo"></credit-file-info>
			</a-tab-pane>
			<a-tab-pane key="6" v-if="disburseInfo" :tab="$t('table.applyInfo.tabsMenu.loanDetail')">
				<loan-detail :disburseInfo="disburseInfo" :loanType="this.loanInfo.loanType"></loan-detail>
			</a-tab-pane>
			<a-tab-pane
				:key="index + 6"
				:tab="$t(`table.applyInfo.financialLabel.loanMap.${info.loanInfo.loanType}`)"
				v-for="(info, index) in disburseEmergencies"
			>
				<emergency-detail :userInfo="userInfo" :emergencyFormInfo="info"></emergency-detail>
			</a-tab-pane>
		</a-tabs>
		<loan-record v-if="disburseInfo && disburseInfo.disburseCertificateFile" :disburseInfo="disburseInfo"></loan-record>
		<audit-record v-if="checkHistories.length > 0" :checkHistories="checkHistories"></audit-record>
	</div>
</template>
<script>
import UserInfo from './UserInfo'
import LoanInfoForm from './LoanInfoForm'
import LoanInfoDetail from './LoanInfoDetail'
import InsurInfo from './InsurInfo'
import CreateInfoDetail from './CreateInfoDetail'
import PersonInfoDetail from './PersonInfoDetail'
import VehicleInfo from './VehicleInfo'
import FinancialInfo from './FinancialInfo'
import ApplicationInfo from './ApplicationInfo'
import CreditFileInfo from './CreditFileInfo'
import LoanDetail from './LoanDetail'
import LoanRecord from './LoanRecord'
import AuditRecord from './AuditRecord'
import EmergencyDetail from './EmergencyDetail'
import { apiApplyInfo } from '@/api/loan'
export default {
	props: {
		// 案件当前进度
		caseStates: {
			type: String,
			require: true
		},
		notify: {
			type: Boolean
		},
		parentNode: {
			type: String
		},
		showEditUpfront: {
			type: Boolean
		},
	},
	data() {
		return {
			beingRevised: false,
			userInfo: {},
			loanInfo: {},
			insuranceData: [],
			createInfo: {},
			personalInfo: {},
			financeInfo: {},
			loanApplyInfo: {},
			loanCarInfos: {},
			disburseInfo: {},
			creditReport: {},
			checkHistories: [],
			checkRecords: [],
			processStatus: {},
			showEditBtn: false,
			editable: false,
			dataComplete: false,
			disburseEmergencies: [],
			detailData: {}
		}
	},
	components: {
		UserInfo,
		LoanInfoForm,
		LoanInfoDetail,
		InsurInfo,
		CreateInfoDetail,
		PersonInfoDetail,
		VehicleInfo,
		FinancialInfo,
		ApplicationInfo,
		CreditFileInfo,
		LoanDetail,
		LoanRecord,
		AuditRecord,
		EmergencyDetail
	},
	created() {
		this.$nextTick(() => {
			switch (this.caseStates) {
				case 'view_detail':
					this.applyInfoInit()
					break
				case 'edit_view_detail':
					this.applyInfoInit()
					this.editable = true
					break
				case 'init_detail':
					this.$refs.loanInfo.editLoanInfo = true
					this.$refs.createInfo.editCreateInfo = true
					break
				case 'edit_detail':
					this.$refs.loanInfo.editLoanInfo = true
					this.$refs.createInfo.editCreateInfo = true
					this.applyInfoInit()
					break
				case 'review_detail':
					this.applyInfoInit()
					this.showEditBtn = true
					break
				default:
					break
			}
		})
	},
	methods: {
		applyInfoInit() {
			apiApplyInfo(this.$route.query.id, this.notify)
				.then((res) => {
					if (!res.personalInfo.incomeSource) {
						res.personalInfo.incomeSource = {
							id: '',
							sourceType: 1,
							company: '',
							employerName: '',
							employerMobile: '',
							employerEmail: '',
							file: ''
						}
					}
					this.userInfo = {
						createSource: res.createSource,
						createTime: res.createTime,
						creatorId: res.creatorId,
						creatorName: res.creatorName,
						customerId: res.customerId,
						statusCode: res.processStatus.statusCode,
						caseId: res.caseId,
						casePrefix: res.casePrefix
					}
					this.loanInfo = res.loanInfo
						? res.loanInfo
						: {
								loanId: null,
								applyAmount: null,
								loanProcessingFee: null,
								creditLifeInsuranceFee: null,
								lessLoanBalance: null,
								crbAmount: null,
								transferAmount: null,
								gpsInstallAmount: null,
								gpsMonthAmount: null,
								firstMonthDeduce: null,
								mortgageRegistrationAmount: null,
								amountPayable: null,
								interestRate: null,
								installmentsNumbers: null,
								firstMonthRepay: null,
								otherMonthRepay: null
						  }
					this.insuranceData = res.loanInsuranceInfos
						? res.loanInsuranceInfos.map((item) => {
								item.insuranceInterest = item.insuranceInterest * 100
								return item
						  })
						: [
								{
									hasInsurance: 0
								}
						  ]
					this.createInfo = {
						cityCode: res.cityCode,
						cityName: res.cityName,
						storeId: res.storeId,
						storeName: res.storeName,
						salesmanId: res.salesmanId,
						salesmanName: res.salesmanName
					}
					this.personalInfo = res.personalInfo ? res.personalInfo : {}
					this.financeInfo = res.financeInfo ? res.financeInfo : {}
					this.loanCarInfos = res.loanCarInfos ? res.loanCarInfos : []
					this.disburseInfo = res.disburseInfo ? res.disburseInfo : null
					this.loanApplyInfo = res.loanApplyInfo ? res.loanApplyInfo : {}
					this.creditReport = res.creditReport ? res.creditReport : {}
					this.checkHistories = res.checkHistories ? res.checkHistories : []
					this.checkRecords = res.checkRecords ? res.checkRecords : []
					this.processStatus = res.processStatus ? res.processStatus : {}
					this.disburseEmergencies = res.disburseEmergencies ? res.disburseEmergencies : []
					if (this.processStatus.currentUserCanProcess) {
						this.processStatus.processNode.id === 'apply_info_confirm_node' ? (this.editable = true) : ''
					}
					this.dataComplete = true
					this.detailData = res
					this.$emit('getCaseDetailsData', { ...res })
				})
				.catch((err) => {
					if (err.code === -1 && err.data === null) {
						this.$emit('getCaseDetailsData', null)
					}
				})
		},
		setInsuranceCompleted(val) {
			this.$emit('setInsuranceCompleted', val)
		},
		setDataChange(params) {
			const key = params.key.split('.')
			key.length > 1 ? (this[key[0]][key[1]] = params.value) : (this[params.key] = params.value)
		},
	}
}
</script>
<style lang="less">
.case-details {
	.ant-input-number,
	.ant-select,
	.ant-input {
		width: 200px;
	}
	.ant-pagination-options {
		.ant-select {
			width: auto;
		}
	}
}
</style>
